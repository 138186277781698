import { computed, ref } from '@vue/composition-api'
import store from '@/store'
import {
  getDecimals, getIntegers, parseRequestError,
} from '@/helpers/helpers'
import {
  transformDatetimeToHumanReadablePretty,
} from '@/helpers/dateTimeHelper'
import SettlementStatusDictionary from '@/dictionary/SettlementStatusDictionary'

export default (props, { root }) => {
  const route = root.$route
  const { customerId } = route.params
  const paymentIntegrations = ref([])
  const organization = ref({ })
  const settlementsToPay = ref([])
  const loading = ref(true)
  const additionalAmount = ref(0)
  const additionalAmountIsHovering = ref(false)
  const showPaymentMethodsModal = ref(false)

  const totalAmount = computed(() => {
    let a = 0
    settlementsToPay.value.forEach(settlement => {
      if (settlement.checked) {
        a += parseFloat(settlement.amount)
      }
    })
    return a + parseFloat(additionalAmount.value ? additionalAmount.value : '0')
  })
  const allChecked = computed(() => (settlementsToPay.value.length > 0 ? settlementsToPay.value[settlementsToPay.value.length - 1].checked : true))
  const data = ref({
    paymentMethod: null,
    integrationId: null,
  })

  const isValid = computed(() => data.value.paymentMethod && data.value.integrationId && totalAmount.value && parseFloat(additionalAmount.value ? additionalAmount.value : '0') >= 0)
  const getLastUsedPaymentMethods = () => JSON.parse(localStorage.getItem('payment-methods')) ?? {}

  const setPaymentMethod = (integrationId, paymentMethod) => {
    data.value.paymentMethod = paymentMethod
    data.value.integrationId = integrationId

    const lastUserPaymentMethods = getLastUsedPaymentMethods()
    lastUserPaymentMethods[customerId] = { integrationId, paymentMethodId: paymentMethod.externalId }
    localStorage.setItem('payment-methods', JSON.stringify(lastUserPaymentMethods))

    showPaymentMethodsModal.value = false
  }

  Promise.all([
    store.dispatch('customers/fetchPaymentIntegrationsForCustomer', customerId),
    store.dispatch('publicStore/fetchSettlementsToPay', customerId),
  ])
    .then(responses => {
      let responseOrganization = []
      let responseSettlements = [];
      [responseOrganization, responseSettlements] = responses
      responseSettlements.items.forEach((settlement, key) => {
        responseSettlements.items[key].checked = true
        responseSettlements.items[key].hovered = false
        responseSettlements.items[key].isOverdue = false
        responseSettlements.items[key].isOverdue = settlement.status === SettlementStatusDictionary.OverduePayment
        if (settlement.type !== 'NegativeBalance') {
          responseSettlements.items[key].service = settlement.calendarEventCustomers[0]?.calendarEvent.service
        }
      })

      const lastUsedPaymentMethods = getLastUsedPaymentMethods()
      if (lastUsedPaymentMethods[customerId]) {
        responseOrganization.integrations.forEach(integration => {
          if (lastUsedPaymentMethods[customerId].integrationId === integration.integrationId) {
            integration.methods.forEach(paymentMethod => {
              if (lastUsedPaymentMethods[customerId].paymentMethodId === paymentMethod.externalId) {
                data.value.paymentMethod = paymentMethod
                data.value.integrationId = integration.integrationId
              }
            })
          }
        })
      } else {
        showPaymentMethodsModal.value = true
      }

      organization.value = responseOrganization.organization
      paymentIntegrations.value = responseOrganization.integrations
      settlementsToPay.value = responseSettlements.items
    })
    .catch(err => parseRequestError(err))
    .finally(() => {
      loading.value = false
    })

  const handleCalendarEventClick = clickedCalendarEvent => {
    let newValue = true
    settlementsToPay.value.forEach((calendarEvent, key) => {
      settlementsToPay.value[key].checked = newValue
      if (clickedCalendarEvent !== 'additional-amount' && clickedCalendarEvent.id === calendarEvent.id) {
        newValue = false
        additionalAmount.value = 0
      }
    })
  }

  const handleCalendarEventMouseover = hoveredCalendarEvent => {
    let newValue = true
    settlementsToPay.value.forEach((calendarEvent, key) => {
      settlementsToPay.value[key].hovered = newValue
      if (hoveredCalendarEvent !== 'additional-amount' && hoveredCalendarEvent.id === calendarEvent.id) {
        newValue = false
      }
    })
  }

  const clearSelection = () => {
    settlementsToPay.value.forEach((calendarEvent, key) => {
      settlementsToPay.value[key].hovered = false
    })
  }

  const processPayment = () => {
    store.dispatch(
      'customers/preparePaymentUrl',
      {
        integrationId: data.value.integrationId,
        payload: {
          amount: totalAmount.value,
          currency: 'PLN',
          description: '',
          customerId,
          paymentMethod: data.value.paymentMethod.externalId,
        },
      },
    )
      .then(res => {
        window.location.href = res.data.redirectUrl
      })
  }

  return {
    paymentIntegrations,
    settlementsToPay,
    organization,
    loading,
    data,
    totalAmount,
    additionalAmount,
    additionalAmountIsHovering,
    allChecked,
    isValid,
    showPaymentMethodsModal,

    handleCalendarEventClick,
    handleCalendarEventMouseover,
    clearSelection,
    setPaymentMethod,
    processPayment,

    getIntegers,
    getDecimals,
    transformDatetimeToHumanReadablePretty,
  }
}
